// Header
header{
  margin-bottom: 40px;
  .navbar-top {
    background: black;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    ul {
      margin-bottom: 0;
      li {
        a {
          color: white;
          font-size: 15px;
          font-weight: 400;
          &:hover {
            color: rgba(255, 255, 255, 0.9);
            text-decoration: none;
          }
        }
      }
    }
  }
  > .container-fluid {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navbar-elizasu {
    background: #3f6aad;
    padding-top: 0;
    padding-bottom: 0;
    a {
      padding: .5rem 1rem !important;
      color: #FFF;
      font-style: normal;
      text-transform: uppercase;
      line-height: 1rem;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
    .configurador{
      padding: 1rem 0;
    }
    .tienda{
      background-color: $white;
      padding: 1rem 0;
      a{
        color: #3f6aad;
      }
    }
    .nav-link{
      letter-spacing: 1px;
    }
    @media (max-width: 991.98px) {
      padding-left: 0;
      padding-right: 0;
      .navbar-collapse {
        margin-top: 16px;
        border-top: 2px solid rgba(232,232,232,.29);
        .container {
          padding-left: 0;
          padding-right: 0;
          .navbar-nav {
            .nav-item {
              &.__brand {
                display: none;
              }
              .nav-link {
                display: flex;
                align-items: center;
                padding: 1em 3em 1em 1em;
                min-height: 3em;
                font-weight: 400;
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }
}
#configurador {
  .loading-container {
    top: -1rem;
    bottom: -1rem;
    left: -1rem;
    right: -1rem;
    background: rgba(0,0,0,.75);
    z-index: $zindex-tooltip;
    display: none;
    .loading {
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 10rem;
    }
  }
  .configurador-index {
		padding-top: 4rem;
		padding-bottom: 4rem;
  }

  .item, .selectable {
		img {
			transition: all 0.5s ease-out;
		}
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
    .h4 {
      font-size: 28px;
      color: $blue;
      padding: 2rem 2rem 1.5rem;
    }
    p{
      padding: 0 2rem;
      margin: 0 0 2rem;
    }
    .item-content{
      height: 100%;
	  cursor: pointer;
      position: relative;
      background: #fff;
      border-bottom: 2px solid #e8e8e8;
      transition: all 0.5s ease-out;
      &:before, &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: all 1s ease;
        z-index: 3;
      }
      &:before{
        border-top: 2px solid #dedede;
        border-bottom: 2px solid #dedede;
        transform: scaleX(0);
      }
      &:after{
        border-left: 2px solid #dedede;
        border-right: 2px solid #dedede;
        transform: scaleY(0);
      }
      &:hover{
        &:before, &:after{
          transform: scale(1);
          opacity: 1;
        }
      }
      p{
        border-top: 1px solid white;
        padding-top: 1rem;
        margin: 1rem;
        color: white;
      }
    }
  }
  .imagen{
    overflow: hidden;
    border-bottom: 4px solid $blue;
    img{
      width: 100%;
    }
  }
  .title-product {
		line-height: 1;
    text-align: center;
    text-transform: uppercase;
  }
  .title-step{
    font-size: rem(40);
    text-transform: uppercase;
    margin: 4rem 0 0;
    @media (max-width: 767px) {
      margin: 2rem 0 0;
      font-size: rem(23);
    }
  }
	.configurador-bottom {
		.h4 {
			color: #fff;
			font-size: 1rem;
		}
	}
	@include media-breakpoint-up(md) {
		.configurador-bottom {
			.h4 {
				font-size: rem(18);
			}
		}
	}
}
#suboptions {
  position: absolute;
  background: rgba(0,0,0,.75);
  z-index: $zindex-tooltip;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.5s ease-out;
  .close-suboptions {
    position: absolute;
    right: 1rem;
    top: .5rem;
    font-size: 3rem;
    color: rgba(255,255,255,1);
    cursor: pointer;
    &:hover {
      color: rgba(255,255,255,.5);
    }
  }
  .suboptions {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: $white;
    div {
      cursor: pointer;
      .item-content{
        background-color: $blue!important;
        .h4{
          font-size: 22px!important;
          color: $white!important;
          padding: 1rem 2rem 0rem!important;
        }
      }
      .active{
        background-color: #00284F!important;
        color: $white!important;
        .imagen{
          border-bottom: 4px solid #00284F!important;
        }
      }
    }
    @media (max-width: 767px) {
      padding: 4rem 6rem;
    }
  }
}
.bloques{
  .item-content{
    position: relative;
    background: #fff;
    border-bottom: 0;
    transition: all 0.5s ease-out;
    &:before, &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: all 1s ease;
      z-index: 3;
    }
    &:before{
      border-top: 2px solid $white!important;
      border-bottom: 2px solid $white!important;
      transform: scaleX(0);
    }
    &:after{
      border-left: 2px solid $white!important;
      border-right: 2px solid $white!important;
      transform: scaleY(0);
    }
    &:hover{
      &:before, &:after{
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.configurador-bottom {
	padding-top: 2rem;
	padding-bottom: 5rem;
	.item-content {
		background-color: $blue!important;
  }
  .active{
    .item-content{
      background-color: #00284F!important;
      color: $white!important;
      .imagen{
        border-bottom: 4px solid #00284F!important;
      }
    }
  }
	.disabled {
		.item-content {
			background-color: $gray-200 !important;
			.h4 {
				color: $gray-600 !important;
			}
		}
	}
}

.container-fluid{
  .accordion{
    padding: 0 1rem;
    .card{
      max-width: 1200px;
      margin: 0 auto;
      .btn-link{
        color: $black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        &:after {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
        }
      }
    }
    .card-body{
      padding: 2rem;
      .row{
        padding: 0 2rem;
      }
      .h4{
        color: $blue;
      }
    }
    .col-md-8,.col-md-4{
      padding: 0.5rem 1rem;
      margin: 0;
    }
  }
}
.form{
  max-width: 1200px;
  margin: 0 auto;
  .legales,.custom-checkbox{
    a{
      color: $blue;
    }
  }
}

.configurador-top {
	background-color: $black;
	position: sticky;
	top: 0;
	z-index: 4;
}

.configurador-buttons {
	background-color: $black;
	.actions {
		display: flex;
		justify-content: space-between;
	}
	.btn {
		align-items: center;
		background-color: transparent;
		border-color: #fff;
		color: #fff;
		display: flex;
		svg {
			color: $white;
			margin-right: .5rem;
			width: rem(15);
			height: rem(15);
			transition: color .15s ease-in-out;
		}
		&:hover, &:focus {
			background-color: #fff;
			color: $black;
			svg {
				color: $black;
			}
		}
	}
	.btn-restart {
		@include media-breakpoint-down(md) {
			border-color: transparent;
			span {
				display: none;
			}
			svg {
				margin-right: 0;
			}
		}
	}
	.btn-continue {
		background-color: $blue;
		border-color: $blue;
		svg {
			margin-left: .5rem;
			margin-right: 0;
		}
		&:hover, &:focus {
			background-color: lighten($blue, 12%);
			color: #fff;
			svg {
				color: #fff;
			}
		}
        &.highlighted {
          animation-duration: 2s;
          animation-fill-mode: both;
          animation-name: flash;
        }
	}
    @keyframes flash {
      0%, 50%, 99.9999% {
        opacity: 1;
        background-color: $blue;
        border-color: $blue;
        border-width: 1px;
      }
      25%, 75% {
        opacity: 0.5;
      }
      100% {
        border-width: 4px;
        border-color: $white;
        background-color: lighten($blue, 12%);
      }
    }
	@include media-breakpoint-down(md) {
		border-top: rem(1) solid lighten($black, 15%);
		padding-top: rem(10);
		padding-bottom: rem(10);
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
	}
	@include media-breakpoint-up(lg) {
		background-color: transparent;
		position: absolute;
		top: rem(45);
		left: 0;
		right: 0;
		pointer-events: none;
		.btn {
			pointer-events: all;
		}
	}
}

.configurador-buttons__group {
	display: flex;
	button {
		&+button {
			margin-left: rem(10);
		}
	}
}

.ratio {
	&:before {
		//background-color: #f3f3f3;
		background-color: lighten($blue, 40%);
		content: '';
		display: block;
	}
}

.ratio-4-3 {
	&:before {
		padding-top: (3/4) * 100%;
	}
}

.ratio-5-3 {
	&:before {
		padding-top: (3/5) * 100%;
	}
}

// Disabled state madness

#configurador {
	.disabled {
		.imagen {
			border-bottom-color: $gray-400;
			.reason-disabled {
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background: rgba(0,0,0,.8);
				color: #FFF;
				width: calc(-20px + 100%);
				padding-top: .5rem;
				padding-bottom: .5rem;
			}
		}
		img {
			filter: grayscale(100%);
			opacity: .3
		}
		.ratio {
			&:before {
				background-color: $gray-300;
			}
		}
		&:hover {
			img {
				transform: none;
			}
		}
		.item-content {
			cursor: not-allowed;
			&:hover {
				&:before, &:after {
			  	opacity: 0;
					transform: none;
				}
			}
		}
	}
}

.item-content__content {
	align-items: center;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	flex-grow: 1;
	justify-content: center;
	padding: rem(12) 1.5rem;
	width: 100%;
	@include media-breakpoint-up(lg) {
		padding: 1rem 2rem;
	}
}

.reason-disabled {
	margin-top: rem(8);
	display: block
}

.imagen {
	.reason-disabled {
		margin-top: 0;
		padding: .5rem;
	}
}

#configurador {
	.item {
		.item-content {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			.imagen {
				width: 100%;
			}
		}
	}
}
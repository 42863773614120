footer {
  border-top: 5px solid #000;
  padding-top: 25px;
  background: #3f6aad;
  color: #FFF;
  font-weight: 300;
  .address {
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    p {
      margin-bottom: 14px;
    }
  }
  strong {
    font-weight: 500;
  }
  a {
    color: white;
    &:hover {
      color: white;
      text-decoration: none;
    }
  }
  p {
    margin-bottom: .8rem;
  }
  .container {
    > .row {
      > div {
        padding-bottom: 20px;
      }
    }
  }
}
.copyright {
  padding-bottom: 20px;
  padding-top: 20px;
  color: rgb(33, 37, 41);
  background: #f8f8f8;
  font-weight: 300;
}

.gmap{
  overflow:hidden;
  padding-bottom:40%;
  position:relative;
  height:0;
  iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
}
.lazyframe{
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $black;
  background-position: center;

  &__title{
    position: absolute;
    top: 0; left: 0; right: 0;
    padding: 15px 17px;
    z-index: 3;

    //display: none; // título oculto
    
    &:after{
      z-index: -1;
    }    
  
  }

  &:hover{
    cursor: pointer;
  }  

  &:before{
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }

  &[data-ratio="16:9"]:before { padding-top: 56.25%; }
  &[data-ratio="4:3"]:before { padding-top: 75%; }
  &[data-ratio="1:1"]:before { padding-top: 100%; }

  iframe{
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
  }

}






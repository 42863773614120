.share{
  ul{
    li{
      a{
        color: $body-color;
        &:hover{
          color: lighten($body-color,15%);
        }
        svg{
          width: 24px;
          height: 24px;
          fill: currentColor;
        }
      }
    }
  }
}
.validation-form{
  .invalid-feedback{
    display: block;
  }
}
.form-control{
  border-radius: 0;
  padding: 0.8rem;
}

input[type="text"]{font: 15px/24px "Lato", Arial, sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

.effect-1{border: 0; border-bottom: 1px solid #ccc;}

.effect-1 ~ .focus-border{position: absolute; bottom: 0; left: 0; width: 0; height: 2px; background-color: #3399FF; transition: 0.4s;}
.effect-1:focus ~ .focus-border{width: 100%; transition: 0.4s;}

.btns-estimation {
  .btn {
    min-width: 20rem;
    @include media-breakpoint-down(md) {
      width: 100%;
      display: block;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

// Legales
.main-textos-legales{
  .container-fluid{
    padding: 0;
  }
  .breadcrumb{
    display: flex;
    justify-content: center;
    border-radius: 0;
    padding: 1rem;
    margin: 0;
    a{
      color: $white;
    }
  }
  .container{
    max-width: 1000px;
    padding: 6rem 0;
  }
  .bg-blue{
    .container{
      padding: 1.5rem 0;
    }
  }
  .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: $blue;
  }
}
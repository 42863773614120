/*
  # Reset
  # Outline
*/


// # Reset
.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  //font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &:focus {
    outline: none;
  }
}

// # Outline
.no-outline {
  &:focus {
    outline: none;
  }
}


.btn-success.disabled, .btn-success:disabled{
  background-color: $black;
  color: $white;
  border: 1px solid white;
}

.btn-success.disabled:hover, .btn-success:disabled:hover, .btn-success:hover{
  &:hover{
    color: $white!important;
    background-color: $blue!important;
  }
}

.btn-select-multiple{
  border: 1px solid $white;
}

.btn-primary{
  background-color: #3f6aad;
  border: 0;
  &:hover{
    background-color: #000;
  }
  svg{
    color: $white;
    width: 15px;
    height: 15px;
    position: relative;
    z-index: 2;
    margin-left: 0.5rem;
    padding-top: 0.2rem;
  }
}


.btn-home {
  background: $black;
  border: 0;
  color: #FFF;
  text-transform: uppercase;
  padding: 1rem 3rem;
  &:hover {
    background: #FFF;
    color: #000;
  }
}

.btn-link{
  color: #3f6aad;
  &:hover{
    text-decoration: none;
  }
}
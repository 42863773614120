/*
  # Background image with object-fit
*/

// # Background image with object-fit
.bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; // esto va ligado con el plugin de https://github.com/fregante/object-fit-images
  /* Si se quiere la posición:
  object-position: bottom;
  font-family: 'object-fit: cover; object-position: bottom;';
  */
}
.bg-blue{
  background-color: $blue;
  background-image: linear-gradient(200deg,#3f6aad 54%,#00284f 85%);
}
.bg-gray{
  background-color: #f3f3f3;
}
.bg-black{
  background-color: $black;
}
.pb-5, .py-5{
  padding: 5rem 0!important;
}
// # Opacities
@each $opacity in $opacities {
  .opacity-#{$opacity} {
   opacity: #{$opacity/100};
  }
}

// Body componets
body{
  font-family: 'Fira Sans', sans-serif;
  color: #3d2815;
}
.container-fluid{
  padding: 0;
}
a{
  abbr[data-original-title], abbr[title]{
    border-bottom: 0;
    text-decoration: none;
  }
}
.progress{
  height: 2rem;
  font-size: 1rem;
  border-radius: 0;
}
.progress-bar{
  background-color: #3f6aad;
}
.dropdown-menu{
  border: 0;
  border-radius: 0;
  a{
    color: #000;
  }
}
.dropdown-item.active, .dropdown-item:active{
  background-color: transparent;
  color: #000;
  text-transform: uppercase;
}

.fs-14 { font-size: rem(14) }

.intro {
	p {
		&:last-child {
      margin-bottom: 0;
    }
	}
}